import React from "react";
import * as classes from './Footer.module.scss';
import GlobalData from '../../data/global.js';

const Footer = () => {
    let footer = GlobalData.footer;
    return (
        <footer className={`${classes.footer}`}>
            <div className={`container ${classes.footer__container}`}>
                <div className={`${classes.footer__left}`}>
                    <p> {footer.copyright.copy}
                        <a href={footer.copyright.URL} target="_blank" rel="noreferrer">
                            <span >{footer.copyright.readMore}</span>
                        </a>
                    </p>
                </div>
                <div className={`${classes.footer__right}`}>
                    <p>
                        <a href="https://www.bbc.co.uk/usingthebbc/cookies/" target="_blank" rel="noreferrer">Cookies</a> |  <a href="https://www.bbc.com/usingthebbc/cookies/how-can-i-change-my-bbc-cookie-settings/" target="_blank" rel="noreferrer" style={{marginLeft:'3px'}}>Ad Choices / Do Not Sell My Info</a>
                    </p>
                </div> 
            </div>

            <div className={`container ${classes.footer__container_mobile}`}>
                <p>
                    {footer.copyright.copy}
                    <a href={footer.copyright.URL} target="_blank"> {footer.copyright.readMore}</a> | 
                    <a href="https://www.bbc.co.uk/usingthebbc/cookies/" target="_blank" rel="noreferrer" style={{marginLeft:'3px'}}>
                        Cookies</a> | 
                    <a href="https://www.bbc.com/usingthebbc/cookies/how-can-i-change-my-bbc-cookie-settings/" target="_blank" rel="noreferrer" style={{marginLeft:'3px'}}>
                        Ad Choices / Do Not Sell My Info
                    </a>
                </p> 
                
            </div>
        </footer>

    )
}

export default Footer