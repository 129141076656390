import React, {useEffect, useRef} from 'react'
import * as classes from './index.module.scss';
import useDocumentHeight from "../../../helpers/useDocumentHeight"
import { getFileName } from '../../../helpers/FileCatching';


/////// GSAP Imports 
import gsap, {Power0} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function VerticalParallax({imagePath, parallaxImages}) {

    let docHeight = useDocumentHeight();
    const parallaxContainer = useRef(null);
    const parallaxTimeline1 = useRef(null);
    const parallaxImage1 = useRef(null);
    const parallaxTimeline2 = useRef(null);
    const parallaxImage2 = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        initParalaxAnimations();
    })

    function initParalaxAnimations(){

        gsap.set(parallaxImage1.current, {bottom:"0%"})
        gsap.set(parallaxImage2.current, {bottom:"0%"})
        
        parallaxTimeline1.current = gsap.to( parallaxImage1.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom -150%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });
        
        parallaxTimeline2.current = gsap.to( parallaxImage2.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom -60%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });
    }

    useEffect(() => {
        ScrollTrigger.refresh(true);
    },[docHeight])

    return (
        <div ref={parallaxContainer} className={`${classes.VerticalParallax} component`}>
            <img ref={parallaxImage1} className={`${classes.VerticalParallax__image_one}`} src={getFileName(imagePath,parallaxImages.mobile.imageOne).default} alt={"Circular images"}/>
            <img ref={parallaxImage2} className={`${classes.VerticalParallax__image_two}`} src={getFileName(imagePath,parallaxImages.mobile.imageTwo).default} alt={"Two lady's hugging"}/>
        </div>
    )
}

export default VerticalParallax