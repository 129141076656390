import React, {useState, useEffect, useRef} from "react"
import Header from "../components/Header/Header"
import Hero from "../components/Hero/Hero"
import Articles from "../components/Articles/Articles"
import "../styles/style.scss"
import { trackCustomEvent } from "../helpers/tracking"

import Content from '../data/content.json'
import GlobalData from "../data/global"

// markup
const IndexPage = () => {

  let progress25Tracked = false;
	let progress50Tracked = false;
	let progress75Tracked = false;
	let progress100Tracked = false;
	let pereviousScrollPercentage = 0

  useEffect( () => {
	  window.addEventListener('scroll', onScroll)
	  // eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

  function onScroll(){
    let articleHeight = document.body.scrollHeight;
		let viewPort= window.innerHeight;
		var percentage = ((getCurrentPosition() + viewPort) - 0)/document.documentElement.scrollHeight * 100;
		var minMaxPercentage = Math.min(100, Math.max(pereviousScrollPercentage, percentage));

    if(minMaxPercentage >= 25){
      if(!progress25Tracked) {
        trackScrollProgress(25);
        progress25Tracked = true;				
      }
    }

    if(minMaxPercentage >= 50){
      if(!progress50Tracked) {
        trackScrollProgress(50);
        progress50Tracked = true;				
      }
    }
    
    if(minMaxPercentage >= 75){
      if(!progress75Tracked) {
        trackScrollProgress(75);
        progress75Tracked = true;
      }
    }

    if(minMaxPercentage >= 100){
      if(!progress100Tracked) {
        trackScrollProgress(100);
        progress100Tracked = true;					
      }
    }
    
    pereviousScrollPercentage= minMaxPercentage

	}

  function trackScrollProgress(progress){
		let trackObject = {
			'category': GlobalData.tracking.gaCategory,
			'action': "Scroll depth",
			'label': progress + "%"
		}
		trackCustomEvent(trackObject)
	}

  function getCurrentPosition (){
		return Math.abs(( window.scrollY || document.body.scrollTop || document.documentElement.scrollTop));
	}

  function onSectionChange(index) {
    
  }

  return (
    <>
      <div id='viewport-height' style={{height:"100vh", position:"absolute", pointerEvents:"none"}}></div>
      <Header isSticky={true}/>
      <Hero content={Content.componentData.hero}/>
      <Articles onEnter={onSectionChange} 
        globalArticleData={Content.componentData.globalArticleData} 
        allArticleContent={Content.componentData.articlesContent}
        globalData={GlobalData}
      />
    </>
  )
}

export default IndexPage
