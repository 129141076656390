export const VhToPx = function (forDevices=[/SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i]) {
    if(navigator){
        let vhInPx = document.getElementById('viewport-height');
        let hasMatched = false;

        forDevices.forEach(element => {
           
            if(navigator.userAgent.match(element)) {
                hasMatched = true
            }
        });
        if(hasMatched){
            return vhInPx.clientHeight+"px"
        }else{
            return null;
        }
    }
}


