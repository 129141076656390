export const trackCustomEvent = function (trackObject) {
    let eventDetals = {
        category:trackObject.category,
        label:trackObject.label,
        value:0
    }
    // console.log("event", trackObject.action, { ...eventDetals })
    if(typeof window !== "undefined" && typeof window.gtag !== "undefined"){
        window.gtag("event", trackObject.action, { 'event_category':eventDetals.category, 'event_label':eventDetals.label, 'value':eventDetals.value })
    }
}