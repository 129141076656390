import React, {useEffect, useState, useRef} from 'react'
import * as classes from './index.module.scss';
import useDocumentHeight from "../../../helpers/useDocumentHeight"
import { getFileName } from '../../../helpers/FileCatching';
import MediaQuery from 'react-responsive';

/////// GSAP Imports 
import gsap, {Power0} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function HorizontalParallax({imagePath, parallaxImages}) {

    let docHeight = useDocumentHeight();

    const parallaxContainer = useRef(null);
    const parallaxTimeline1 = useRef(null);
    const parallaxImage1 = useRef(null);
    const parallaxTimeline2 = useRef(null);
    const parallaxImage2 = useRef(null);
    const parallaxTimeline3 = useRef(null);
    const parallaxImage3 = useRef(null);
    const parallaxTimeline4 = useRef(null);
    const parallaxImage4 = useRef(null);
    const parallaxTimeline5 = useRef(null);
    const parallaxImage5 = useRef(null);
    const parallaxTimeline6 = useRef(null);
    const parallaxImage6 = useRef(null);
    const parallaxTimeline7 = useRef(null);
    const parallaxImage7 = useRef(null);

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        initParalaxAnimations();
    })

    function initParalaxAnimations(){

        gsap.set(parallaxImage1.current, {bottom:"0%"})
        gsap.set(parallaxImage2.current, {bottom:"0%"})
        gsap.set(parallaxImage3.current, {bottom:"0%"})
        gsap.set(parallaxImage4.current, {bottom:"0%"})
        gsap.set(parallaxImage5.current, {bottom:"0%"})
        gsap.set(parallaxImage6.current, {bottom:"0%"})
        gsap.set(parallaxImage7.current, {top:"0%"})
        

        /////// Man on left
        parallaxTimeline1.current = gsap.to( parallaxImage1.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 90%",
                trigger:parallaxContainer.current,
                end:"bottom -50%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });
        
        parallaxTimeline5.current = gsap.to( parallaxImage5.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 85%",
                trigger:parallaxContainer.current,
                end:"bottom -35%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });

        /////// Mother and daughter right
        parallaxTimeline2.current = gsap.to( parallaxImage2.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom -150%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });

        parallaxTimeline3.current = gsap.to( parallaxImage3.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom 0%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });
        
        parallaxTimeline4.current = gsap.to( parallaxImage4.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom -60%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });
        
        parallaxTimeline6.current = gsap.to( parallaxImage6.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 100%",
                trigger:parallaxContainer.current,
                end:"bottom -20%",
                // markers:true
            },
            bottom:"100%",
            ease:Power0.easeNone
        });

        /////// Lady top left

        parallaxTimeline7.current = gsap.to( parallaxImage7.current,{
            scrollTrigger:{
                scrub: true,
                start: "bottom 90%",
                trigger:parallaxContainer.current,
                end:"bottom 0%",
                // markers:true
            },
            top:"-90%",
            ease:Power0.easeNone
        });
    }

    useEffect(() => {
        ScrollTrigger.refresh(true);
    },[docHeight])

    return (
        <div ref={parallaxContainer} className={`${classes.HorizontalParallax} component`}>
            <MediaQuery minWidth={classes.screenSm} maxWidth={classes.screenLgMax}>
                <img ref={parallaxImage1} className={`${classes.HorizontalParallax__image_one}`} src={getFileName(imagePath,parallaxImages.tablet.imageOne).default} alt={""}/>
                <img ref={parallaxImage2} className={`${classes.HorizontalParallax__image_two}`} src={getFileName(imagePath,parallaxImages.tablet.imageTwo).default} alt={""}/>
                <img ref={parallaxImage3} className={`${classes.HorizontalParallax__image_three}`} src={getFileName(imagePath,parallaxImages.tablet.imageThree).default} alt={""}/>
                <img ref={parallaxImage4} className={`${classes.HorizontalParallax__image_four}`} src={getFileName(imagePath,parallaxImages.tablet.imageFour).default} alt={""}/>
                <img ref={parallaxImage5} className={`${classes.HorizontalParallax__image_five}`} src={getFileName(imagePath,parallaxImages.tablet.imageFive).default} alt={""}/>
                <img ref={parallaxImage6} className={`${classes.HorizontalParallax__image_six}`} src={getFileName(imagePath,parallaxImages.tablet.imageSix).default} alt={""}/>
                <img ref={parallaxImage7} className={`${classes.HorizontalParallax__image_seven}`} src={getFileName(imagePath,parallaxImages.tablet.imageSeven).default} alt={""}/>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                <img ref={parallaxImage1} className={`${classes.HorizontalParallax__image_one}`} src={getFileName(imagePath,parallaxImages.desktop.imageOne).default} alt={""}/>
                <img ref={parallaxImage2} className={`${classes.HorizontalParallax__image_two}`} src={getFileName(imagePath,parallaxImages.desktop.imageTwo).default} alt={""}/>
                <img ref={parallaxImage3} className={`${classes.HorizontalParallax__image_three}`} src={getFileName(imagePath,parallaxImages.desktop.imageThree).default} alt={""}/>
                <img ref={parallaxImage4} className={`${classes.HorizontalParallax__image_four}`} src={getFileName(imagePath,parallaxImages.desktop.imageFour).default} alt={""}/>
                <img ref={parallaxImage5} className={`${classes.HorizontalParallax__image_five}`} src={getFileName(imagePath,parallaxImages.desktop.imageFive).default} alt={""}/>
                <img ref={parallaxImage6} className={`${classes.HorizontalParallax__image_six}`} src={getFileName(imagePath,parallaxImages.desktop.imageSix).default} alt={""}/>
                <img ref={parallaxImage7} className={`${classes.HorizontalParallax__image_seven}`} src={getFileName(imagePath,parallaxImages.desktop.imageSeven).default} alt={""}/>
            </MediaQuery>
        </div>
    )
}

export default HorizontalParallax