import React, {useRef, useEffect, useState} from "react";
import Lottie from 'react-lottie';
import * as classes from './Hero.module.scss';
import OutputHTML from '../../helpers/OutputHTML.js';
import useDocumentHeight from "../../helpers/useDocumentHeight"
import { VhToPx } from "../../helpers/VhToPx";
import { getFileName } from '../../helpers/FileCatching';
import animationData from '../../lotties/HeroTitleLoad.json'
import HorizontalParallax from "../Parallax/HorizontalParallax/index"
import VerticalParallax from "../Parallax/VerticalParallax/index"
const heroImages = require.context('../../images/components/hero/', true, /.*\.(gif|png|jpe?g|svg)$/i);
const contentImages = require.context('../../images/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Hero({content}) {
    const [heroStyleOverrides, setHeroStyleOverrides] = useState({});
    const dynamicImage = {
        loop: false,
        autoplay: true, 
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    useEffect(() => {
        setHeroStyleOverrides((VhToPx() && {height:VhToPx()}))
    }, [])

    return (
        <div className={`${classes.Hero} component`} style={heroStyleOverrides}>
            <VerticalParallax imagePath={heroImages} parallaxImages={content.imageParallax}/>
            <HorizontalParallax imagePath={heroImages} parallaxImages={content.imageParallax}/>
            <header>
                <div className={`container ${classes.Hero__titles}`}>
                    <div className={`container ${classes.Hero__lottie_container}`}>
                        <Lottie 
                            options={dynamicImage}
                        />
                    </div>
                    <div className={`${classes.Hero__titles__title_container}`}>
                        <h1 className={`${classes.Hero__title}`} dangerouslySetInnerHTML={OutputHTML(content.headline)}></h1>
                    </div>
                </div>
            </header>
            <div className={`${classes.Hero__scroll_container}`}>
                <img alt={'dot'} src={getFileName(contentImages,'oval.svg').default}/>
                <h2 dangerouslySetInnerHTML={OutputHTML(content.scrollText)}></h2>
                <img alt={'dot'} src={getFileName(contentImages,'oval.svg').default}/>
            </div>
           
        </div>
    )
}

export default Hero