import React, {useEffect, useRef, useState} from "react"
import {useMediaQuery} from 'react-responsive';
import * as classes from './Longevity.module.scss'
import { getFileName } from '../../../helpers/FileCatching';
import OutputHTML from "../../../helpers/OutputHTML";
import ArticleSection from "../../../sharedComponents/ArticleSection/ArticleSection";
import Summary from "../../Summary/Summary"
import ScrollArrow from "../../../sharedComponents/ScrollArrow/ScrollArrow";

const imagePath = require.context('../../../images/components/Articles/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Longevity({content, globalArticleData, globalData}) {

    const isTablet = useMediaQuery({
        query: `(min-width: ${classes.screenSm})`
    })

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })

    return (
        <div className={`${classes.Longevity} component ArticleSection`} style={isDesktop ? {background: content.backgroundColorHorizontal} : {background: content.backgroundColorVertical} }>
            <div className={`ArticleContainer`}>
                <div className={`ArticleTitleContainer`}>
                    <h1 className={`sectionHeader`} dangerouslySetInnerHTML={OutputHTML(content.title)}></h1>
                    <p className={`clickToDiscoverText`} dangerouslySetInnerHTML={OutputHTML(globalArticleData.clickCopy)}></p>
                </div>
                <ArticleSection content={content} globalData={globalData}/>
            </div>
            {/* <div className={`ArticleSummary`}>
                <Summary content={content.summary}/>
            </div> */}
            <div className={`ArticleScroll`}>
                <ScrollArrow content={globalArticleData.scrollCopy} position={""}/>
            </div>
        </div>
    )
}

export default Longevity