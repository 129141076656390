//////// Package Imports 
import React, {useRef, useEffect, useState} from "react";
import MediaQuery from 'react-responsive';

//////// Component Imports 
import Inclusivity from "./Inclusivity/Inclusivity";
import Exploration from "./Exploration/Exploration";
import Independence from "./Independence/Independence";
import Longevity from "./Longevity/Longevity";
import Summary from "../Summary/Summary";
import Cta from "../Cta/Cta";
import Footer from "../Footer/Footer";

//////// Controller imports
import HorizontalScrollController from "./HorizontalScrollController";
import VerticalArticleTriggerController from "./VerticalArticleTriggerController";

//////// Helpers
import useWindowSize from "../../helpers/useWindowSize";

//////// Styling Imports 
import * as classes from './Articles.module.scss';


function Articles({allArticleContent, globalArticleData, onEnter, globalData}) {

    let allContentContainer = useRef(null);
    let allArticlesContainer = useRef(null);
    // useref should always be declared with const so it doesnt get reassigned accidently and cause bugs
    let [articleSpacing, setArticleSpacing] = useState(0);
    let [pinItemsNew, setPinItemsNew] = useState([]);
    // use state hook should always be declared with const. let is for variables that will re assigned ie let pinitem = 0; pinitem = 1; usestate isnt reassigning the variable, its just updating the value
    let [windowWidth] = useWindowSize();
    // this should also be const as its just descructuring the useWindowSize value which is from a usestate inside the hook

    let allPinTriggerRefs = useRef([])
    let articlesContainer = useRef(null)

    const ArticleComponent = ({content}) => {

        switch (content.id) {
            case "Inclusivity":
                return(
                    <Inclusivity content={content} contentContainer={articlesContainer} globalArticleData={globalArticleData} globalData={globalData}/>
                )
                break;
        
            case "Exploration":
                return(
                    <Exploration content={content} globalArticleData={globalArticleData} globalData={globalData}/>
                )
                break;

            case "Independence":
                return(
                    <Independence content={content} globalArticleData={globalArticleData} globalData={globalData}/>
                )
                break;

            case "Longevity":
                return(
                    <Longevity content={content} globalArticleData={globalArticleData} globalData={globalData}/>
                )
                break;

            default:
                return( <div></div> )
                break;
        }
    }

    useEffect(() => {

        let tempItems = []

        allArticleContent.articleSectionsWithLink.forEach((element, index) => {
            tempItems.push(
                {
                    title:element.id,
                    // fontColor:element.content.textColor,
                    // backgroundColour:classes[element.backgroundClass],
                    trigger:allPinTriggerRefs.current[index],
                    // scrolToIndent:element.scrollIndent
                    scrolToIndent:0
                }
            )
        })
    
        setPinItemsNew(tempItems)

    }, [allArticleContent.articleSectionsWithLink])

    function onHorizontalSectionEntered(index){
        onEnter(index+2)
    }

    useEffect(() => {
        if(allContentContainer.current){
            setArticleSpacing(allContentContainer.current.clientWidth)
        }
    }, [windowWidth])

    return (
        <>
            <MediaQuery maxWidth={classes.screenLgMax}>
                <div ref={articlesContainer} className={`${classes.Articles} `}>
                    <div>
                        <div>
                            
                            {
                                allArticleContent.articleSectionsWithLink.map((content,index) =>{
                                    return(
                                        <VerticalArticleTriggerController key={index} articleIndex={index}>
                                            {/* when looping through content it's important to add a unique key to the parent element of the component. Best practice is a using a UUID but using the index is fine */}
                                            {/* <VerticalArticleTriggerController articleIndex={index} key={index}> */}
                                            <ArticleComponent  content={allArticleContent.articleSectionsWithLink[index]}/>
                                            {/* instead of using allArticleContent.articleSectionsWithLink[index], the same can be achieved by using the content parameter. <ArticleComponent content={content} */}
                                        </VerticalArticleTriggerController>
                                    )
                                })
                            }
                            <VerticalArticleTriggerController articleIndex={allArticleContent.articleSectionsWithLink.length}>
                                <Summary type={"vertical"} content={allArticleContent.summary}/>
                                <Cta type={"vertical"} content={allArticleContent.cta} globalArticleData={globalArticleData} globalData={globalData}/>
                                <Footer />
                            </VerticalArticleTriggerController>
                        </div>
                    </div>
                </div>
            </MediaQuery>
            <MediaQuery minWidth={classes.screenLg}>
                <div ref={articlesContainer} style={{marginBottom:articleSpacing}} className={`${classes.Articles}`}>

                    <HorizontalScrollController onSectionIndex={onHorizontalSectionEntered}  navItems={pinItemsNew} navItemsPinTrigger={allArticlesContainer} navItemsSelectionTrigger={allPinTriggerRefs}>   
                        <span style={{display:"flex", position:"absolute",width:"100%"}} ref={allContentContainer}></span>
                        <span ref={allArticlesContainer} style={{display:"flex"}}>
                        {
                            allArticleContent.articleSectionsWithLink.map((content,index) =>{
                                return(
                                    <span key={index} style={{display:"flex"}} ref={(ref) => {allPinTriggerRefs.current[index] = {current:ref}}}>
                                        <ArticleComponent content={content}/>
                                    </span>
                                )
                            })
                        }
                        </span>
                        <Summary type={"horizontal"} content={allArticleContent.summary}/>
                        <Cta type={"horizontal"} content={allArticleContent.cta} globalArticleData={globalArticleData} globalData={globalData}/>
                    </HorizontalScrollController>

                </div>
            </MediaQuery>
        </>
    )
}

export default Articles