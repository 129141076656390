// extracted by mini-css-extract-plugin
export var HorizontalParallax = "index-module--HorizontalParallax--IU1h5";
export var HorizontalParallax__image_five = "index-module--HorizontalParallax__image_five--ufFmY";
export var HorizontalParallax__image_four = "index-module--HorizontalParallax__image_four--qONbS";
export var HorizontalParallax__image_one = "index-module--HorizontalParallax__image_one--oIik7";
export var HorizontalParallax__image_seven = "index-module--HorizontalParallax__image_seven--t2gUG";
export var HorizontalParallax__image_six = "index-module--HorizontalParallax__image_six--BlCsr";
export var HorizontalParallax__image_three = "index-module--HorizontalParallax__image_three--zNd6E";
export var HorizontalParallax__image_two = "index-module--HorizontalParallax__image_two--MROAi";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";