import React, {useRef, useEffect} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function VerticalArticleTriggerController({children, onEntered, articleIndex}) {

    let articleTrigger = useRef(null)
    let articleScrolLTrigger = useRef(null)
    let pinRef = useRef(null)
    
    // useEffect(() => {
    //     initScrollPin();
    // },[])
    //eslint-disable-next-line react-hooks/exhaustive-deps

    function initScrollPin(){

        let showMarkers = true;

        articleScrolLTrigger.current = ScrollTrigger.create({
            trigger: articleTrigger.current,
            start:() => `top 100`,
            end:`bottom`,
            pinSpacing:false,
            onEnter:() => {onEntered(articleIndex)},
            onEnterBack:() => {onEntered(articleIndex)}
        })
        
    }
    
    return (
        <div ref={articleTrigger}>
            <>{children}</>
        </div>

    )

}

export default VerticalArticleTriggerController