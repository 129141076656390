import React, { useState, useRef, useEffect } from "react";
import * as classes from "./Cta.module.scss";
import OutputHTML from "../../helpers/OutputHTML.js";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import Footer from "../Footer/Footer";
import { getFileName } from "../../helpers/FileCatching";
import { trackCustomEvent } from "../../helpers/tracking";
import { VhToPx } from "../../helpers/VhToPx";
const contentImages = require.context("../../images/", true, /.*\.(gif|png|jpe?g|svg)$/i);

function Cta({ content, globalData }) {
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [ctaStyleOverrides, setCtaStyleOverrides] = useState({});
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const dateTime = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  const CtaRef = useRef(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    

    try {
      await fetch(process.env.GATSBY_ENDPOINT_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ Answer: inputValue, Date: dateTime }),
      });
      setSuccess(true);
      setError(false);
      onSubmitClick();
    } catch (error) {
      console.error(error);
      setSuccess(false);
      setError(true);
    }
    setLoading(false);
  };

  function onSubmitClick(){

    let trackObject = {
      category: globalData.tracking.gaCategory,
      action: "Click",
      label: "Free-Text: Submit"
    }
    trackCustomEvent(trackObject)
  }

  function onFacebookClick(){
    let trackObject = {
      category: globalData.tracking.gaCategory,
      action: "Click",
      label: "Socials: Facebook"
    }
    trackCustomEvent(trackObject);
  }

  function onTwitterClick(){
    let trackObject = {
      category: globalData.tracking.gaCategory,
      action: "Click",
      label: "Socials: Twitter"
    }
    trackCustomEvent(trackObject);
  }

  function onLinkedInClick(){
    let trackObject = {
      category: globalData.tracking.gaCategory,
      action: "Click",
      label: "Socials: Linkedin"
    }
    trackCustomEvent(trackObject);
  }

  useEffect(() => {
    setCtaStyleOverrides((VhToPx() && {height:VhToPx()}))
}, [])

  return (
    <div ref={CtaRef} className={`${classes.Cta} component`} style={ctaStyleOverrides}>
      <div className={`${classes.Cta__copy_container}`}>
        <h1 className={`sectionHeader`} dangerouslySetInnerHTML={OutputHTML(content.title)}></h1>
        <p dangerouslySetInnerHTML={OutputHTML(content.question)}></p>
      </div>
      <div>
        <form onSubmit={handleSubmit}>
          {success ? (
            <p>Thank you! Your answer has been submitted.</p>
          ) : (
            <>
              <textarea
                disabled={loading}
                maxLength="800"
                rows='4'
                value={inputValue}
                placeholder={content.placeholder}
                onChange={(event) => setInputValue(event.target.value)}
              >
              </textarea>
              <button type="submit" disabled={loading || !inputValue.length}>
                {loading ? "Submitting..." : "Submit"}
              </button>
            </>
          )}
          {error && <div>Submission failed.</div>}
        </form>
      </div>
      <div className={`${classes.Cta__social_share__container}`}>
        <FacebookShareButton
          quote={globalData.metaData.facebookDescription}
          url={`${globalData.metaData.urlPath}`}
          onClick={() => {onFacebookClick()}}
        >
          <div className={`${classes.Cta__social_share__img_container}`}>
            <img src={getFileName(contentImages, 'icons/facebook.svg').default} alt={"Facebook Icon"}/>
          </div>
        </FacebookShareButton>
        <TwitterShareButton
          title={`${globalData.metaData.twitterTitle} ${globalData.metaData.twitterDescription}`}
          url={`${globalData.metaData.urlPath}`}
          onClick={() => {onTwitterClick()}}
        >
          <div className={`${classes.Cta__social_share__img_container}`}>
            <img src={getFileName(contentImages, 'icons/twitter.svg').default} alt={"Twitter Icon"}/>
          </div>
        </TwitterShareButton>
        <LinkedinShareButton
          url={globalData.metaData.urlPath}
          onClick={() => {onLinkedInClick()}}
        >
          <div className={`${classes.Cta__social_share__img_container}`}>
            <img src={getFileName(contentImages, 'icons/linkedin.svg').default} alt={"LinkedIn Icon"}/>
          </div>
        </LinkedinShareButton>
      </div>
      <div className={`${classes.Cta__footer_container}`}>
        <Footer />
      </div> 
    </div>
  );
}

export default Cta;
