import React, {useRef, useEffect, useState} from "react";


//////// Styling Imports 
import * as classes from './index.module.scss';

function NavItem({children, selectedNavStates, id}) {

    let pinRef = useRef(null)
    
    useEffect(() => {
        
        if(selectedNavStates){
            //Add class for it being selected
            pinRef.current.classList.add(id+'_pin__selected')

        }else{
            //Remove class for it being selected
            pinRef.current.classList.remove(id+'_pin__selected')
        }
    },[selectedNavStates])
    //eslint-disable-next-line react-hooks/exhaustive-deps
    
    return (

        <div ref={pinRef} className={`${classes.NavItem} ${(selectedNavStates && classes.NavItem__selected)} ${id}_pin`}>
            {children}
        </div>

    )

}

export default NavItem