import React from "react";
import * as classes from './ScrollArrow.module.scss';
import OutputHTML from '../../helpers/OutputHTML.js';
import { getFileName } from '../../helpers/FileCatching'

const imagePath = require.context('../../images/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function ScrollArrow({content, position}) {
   
    return (
        <div className={`${classes.ScrollArrow} component`} style={{top:position.top, bottom: position.bottom, left: position.left }}>
            <p className={`${classes.ScrollArrow__copy}`} dangerouslySetInnerHTML={OutputHTML(content)}></p>
            <img alt={"Arrow pointing right"} src={getFileName(imagePath,"arrow.svg").default}/>
        </div>
    )
}

export default ScrollArrow