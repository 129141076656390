import React, {useEffect, useState, useRef} from 'react'
import './Header.scss';
import GlobalData from '../../data/global.js';
import OutputHTML from '../../helpers/OutputHTML.js';
import { Helmet } from 'react-helmet';
import { trackCustomEvent } from "../../helpers/tracking"

/////// GSAP Imports 
import gsap, {Power0} from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

function onClientClick(){
    let trackObject = {
        category: GlobalData.tracking.gaCategory,
        action: "Click",
        label: "Client logo header"
    }
    trackCustomEvent(trackObject)
}

function onAboutClick(){
    let trackObject = {
        category: GlobalData.tracking.gaCategory,
        action: "Click",
        label: "About this"
    }
    trackCustomEvent(trackObject)
}

export default function Header({headerClass, isSticky}) {
  
  let header = GlobalData.header;
  let headerBG = useRef();
  let whiteBgTimeline = useRef();

  useEffect(() => {
    initWhiteBacgroundFade();
  }, [])

  function initWhiteBacgroundFade(){

    gsap.set(headerBG.current, {backgroundColor:"rgba(255, 255, 255, 0)"})
  
    whiteBgTimeline.current = gsap.to( headerBG.current,{
      scrollTrigger:{
          scrub: true,
          start: "top 0px",
          trigger:document.body,
          end:"+=75px"
      },
      backgroundColor:"rgba(255, 255, 255, 0.8)",
      backdropFilter: "blur(2px)",
      ease:Power0.easeNone
    });
  }

  function headerMarkup(params) {
    return (
      <div ref={headerBG} className={`header ${headerClass}`}>
          <div className={`header__left_container`}>
            <div className={`header__left_container__logo_container`}>
              <img className={`header__left_container__bbc_logo`} src={header.bbc.logoPath} alt={header.bbc.alt}/>
            </div>
          </div>

          <div className={`header__right_container`}>
              <div className={`header__right_container__client_logo_container`}>
                <div className={`header__right_container__client_logo_copy`}>
                  <p dangerouslySetInnerHTML={ OutputHTML( header.client.copy)}></p>
                </div>
                <div className={`header__right_container__client_logo_wrapper`}>
                  <a onClick={() => {onClientClick()}} href={header.client.clientURL} target="_blank" rel="noreferrer">
                    <img className={`header__right_container__client_logo`} src={header.client.logoPath} alt={header.client.alt}/>
                  </a>
                </div>
              </div>
              <a onClick={() => {onAboutClick()}} href={header.about.aboutURL} target="_blank" rel="noreferrer">
                <div className={`header__right_container__about_container`}>
                  <div className={`header__right_container__about_icon_container`}>
                    <img className={`header__right_container__about_icon`} src={header.about.logoPath} alt={header.about.alt}/>
                  </div>
                  <div className={`header__right_container__about_copy`}>
                    <p dangerouslySetInnerHTML={ OutputHTML( header.about.copy)}></p>
                  </div>
                </div>
              </a>
          </div>
      </div>
    )
  }

  return (
    <>
      <Helmet>
        <title>{ GlobalData.pageTitle }</title>
        <meta name="keywords" content={GlobalData.keywords}/>
        <meta name="description" content={GlobalData.pageDescription} />
        <meta property="og:url" content={GlobalData.metaData.urlPath} />
        <meta property="og:title" content={GlobalData.metaData.title} />
        <meta property="og:description" content={GlobalData.metaData.socialDescription} />
        <meta property="og:image" content={GlobalData.metaData.facebookSocialImage} />
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:title" content={GlobalData.metaData.twitterTitle} />
        <meta name="twitter:description" content={GlobalData.metaData.twitterDescription}/>
        <meta name="twitter:image" content={GlobalData.metaData.twitterSocialImage} />
      </Helmet>
      {(isSticky
        ?
        <>
          {/* <div className={`header__sticky_filler`}>
            {headerMarkup()}
          </div> */}
          <div className={`header__sticky`}>
          {headerMarkup()}
          </div>
        </>
        :
        ""
      )}


    </>
  )
}