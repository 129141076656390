// extracted by mini-css-extract-plugin
export var ArticleSection = "ArticleSection-module--ArticleSection--kIAnn";
export var ArticleSection__card = "ArticleSection-module--ArticleSection__card--DKXKk";
export var ArticleSection__card_container = "ArticleSection-module--ArticleSection__card_container--iL3de";
export var ArticleSection__image_container = "ArticleSection-module--ArticleSection__image_container--zAEN2";
export var ArticleSection__title = "ArticleSection-module--ArticleSection__title--5Mgnz";
export var ArticleSection__type = "ArticleSection-module--ArticleSection__type--yBjVB";
export var screenLg = "992px";
export var screenLgMax = "991px";
export var screenMd = "768px";
export var screenMdMax = "767px";
export var screenSm = "576px";
export var screenSmMax = "575px";
export var screenXl = "1200px";
export var screenXlMax = "1199px";