import React, {useRef, useEffect, useState} from "react";
import * as classes from './Summary.module.scss';
import { VhToPx } from "../../helpers/VhToPx";
import OutputHTML from '../../helpers/OutputHTML.js';

function Summary({content}) {
    
    const [summaryStyleOverrides, setSummaryStyleOverrides] = useState({});

    useEffect(() => {
        setSummaryStyleOverrides((VhToPx() && {height:VhToPx()}))
    }, [])

    return (
        <div className={`${classes.Summary} component`} style={summaryStyleOverrides}>
            <div className={`${classes.Summary__content_container}`}>
                <h1 dangerouslySetInnerHTML={OutputHTML(content.title)}></h1>
                <div dangerouslySetInnerHTML={OutputHTML(content.description)}></div>
            </div>
        </div>
    )
}

export default Summary