import { useState, useLayoutEffect } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

const useDocumentHeight = () => {
 
  const [docHeight, setDocHeight] = useState([0]);
  
  useLayoutEffect(() => {

    const resizeObserver = new ResizeObserver(entries =>
      setDocHeight(entries[0].target.clientHeight)
    )

    
    // start observing a DOM node
    resizeObserver.observe(document.body)
    
    
  }, []);

  return docHeight;

}


export default useDocumentHeight;

