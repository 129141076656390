import bbc_logo from '../images/bbc_logo.png';
import client_logo from '../images/client_logo.png';
import about_icon from '../images/about_icon.png';
import social_image from '../images/social.jpg'
import social_twitter from '../images/socialtwitter.jpg'

let liveDomain = "https://www.bbc.com"
let liveUrlBase = liveDomain+"/storyworks/specials/technologys-golden-age" 

let GlobalData = {
    CDNPath:"https://d1wy21vtjs6qq7.cloudfront.net/storyworks/specials/technologys-golden-age",
    pageTitle:"Technology's Golden Age: an online film series",
    pageDescription:"Inclusive technology for long lives, well lived.",
    keywords:"",
    liveURL:liveUrlBase+"/",
    tracking:{
        gaID:"",
        gaCategory:"CTA"
    },
    metaData:{
        title:"#Ad Advertisement Feature presented by CTA. Check out this film series #TechnologysGoldenAge",
        facebookDescription:"This online film series shows how technology can help us live better lives at every age 💙",
        facebookSocialImage:liveUrlBase+"/social.jpg",
        twitterTitle:"Technology's Golden Age: an online film series",
        twitterDescription:"Inclusive technology for long lives, well lived. @BBCStoryWorks",
        twitterSocialImage:liveUrlBase+"/socialtwitter.jpg",
        urlPath:liveUrlBase+"/"
    },
    header:{
        bbc:{
            logoPath: bbc_logo,
            bbcURL: "http://www.bbc.co.uk/storyworks/",
            alt: "BBC Storyworks"
        },
        client:{
            logoPath: client_logo,
            clientURL: "https://www.cta.tech/",
            alt: "Consumer Technology Association Logo",
            copy: "Advertisement<br class='br-all'/>feature <br class='br-smMd'/>presented by"
        },
        about:{
            logoPath: about_icon,
            aboutURL:"https://bbcnews.bbcstudios.com/advertising/bbc-storyworks/programme-partnership/bbc-storyworks-content-definitions/",
            alt: "About icon",
            copy:"About this"
        }
    },
    footer:{
        copyright:{
            copy:"Copyright © 2023 BBC. The BBC is not responsible for the content of external sites.",
            readMore:" Read more",
            URL:"https://www.bbc.com/help/web/links/"
        },
        cookies:{
            cookieCopy:"Cookies",
            cookieURL:"https://www.bbc.co.uk/usingthebbc/cookies/",
            adChoices:"Ad Choices/ Do Not Sell My Info",
            adChoicesURL: "https://www.bbc.com/usingthebbc/cookies/how-does-the-bbc-use-cookies-for-advertising/"
        }

    }
}

export default GlobalData;