import React from "react"
import {useMediaQuery} from 'react-responsive';
import * as classes from './Exploration.module.scss'
import OutputHTML from "../../../helpers/OutputHTML";
import ArticleSection from "../../../sharedComponents/ArticleSection/ArticleSection";
import Quote from "../../../sharedComponents/Quote/Quote";
import ScrollArrow from "../../../sharedComponents/ScrollArrow/ScrollArrow";

function Exploration({content, globalArticleData, globalData}) {

    const isTablet = useMediaQuery({
        query: `(min-width: ${classes.screenSm})`
    })

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })

    return (
        <div className={`${classes.Exploration} component ArticleSection`} style={isDesktop ? {background: content.backgroundColorHorizontal} : {background: content.backgroundColorVertical} }>
            <div className={`ArticleContainer`}>
                <div className={`ArticleTitleContainer`}>
                    <h1 className={`sectionHeader`} dangerouslySetInnerHTML={OutputHTML(content.title)}></h1>
                    <p className={`clickToDiscoverText`} dangerouslySetInnerHTML={OutputHTML(globalArticleData.clickCopy)}></p>
                </div>
                <ArticleSection content={content} globalData={globalData}/>
            </div>
            
            {(content.quoteText !== "" ? 
                <div className={`ArticleQuote`}>
                    <Quote content={content} globalArticleData={globalArticleData}/>
                </div>  
                :
                ""
            )}
            <div className={`ArticleScroll`}>
                <ScrollArrow content={globalArticleData.scrollCopy} position={content.scrollPosition.title.desktop}/>
            </div>
        </div>
    )
}

export default Exploration