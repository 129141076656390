import React from "react";
import * as classes from './Quote.module.scss';
import OutputHTML from '../../helpers/OutputHTML.js';
import { getFileName } from '../../helpers/FileCatching';
import ScrollArrow from "../ScrollArrow/ScrollArrow";

const imagePath = require.context('../../images/components/Articles/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function Quote({content, globalArticleData}) {
    
    return (
        <div className={`${classes.Quote} component`}>
            <div dangerouslySetInnerHTML={OutputHTML(content.quoteText)}></div>
            <ScrollArrow content={globalArticleData.scrollCopy} position={content.scrollPosition.quote}/>
        </div>
    )
}

export default Quote