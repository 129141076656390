import React, {useEffect, useRef, useState} from "react"
import {useMediaQuery} from 'react-responsive';

import * as classes from './ArticleSection.module.scss';
import OutputHTML from '../../helpers/OutputHTML.js';
import { getFileName } from '../../helpers/FileCatching';
import { helperTrackCustomEvent, trackCustomEvent } from "../../helpers/tracking"

const imagePath = require.context('../../images/components/Articles/', true, /.*\.(gif|png|jpe?g|svg)$/i);

function ArticleSection({content, globalArticleData, globalData}) {

    function onArticleClick(headline){
        let trackObject = {
            category: globalData.tracking.gaCategory,
            action: "Click",
            label: "Promo: " + headline
          }
        trackCustomEvent(trackObject)
    }

    // console.log(content)

    const isTablet = useMediaQuery({
        query: `(min-width: ${classes.screenSm})`
    })

    const isDesktop = useMediaQuery({
        query: `(min-width: ${classes.screenLg})`
    })

    return (
        <div className={`${classes.ArticleSection} component`}>
            <div className={`${classes.ArticleSection__card_container}`}>
                {content.cards.map((card, index) => (
                    <a key={index} href={card.url} className={`${classes.ArticleSection__card}`}
                        style={isDesktop ? {marginTop: card.margin.desktop} : {marginTop: ''} }
                        onClick={() => { onArticleClick(card.headline)}}
                    >
                        <div className={`${classes.ArticleSection__image_container}`}>
                            {(isDesktop ? 
                                <img alt={card.imageAlt} src={getFileName(imagePath,card.image.desktop).default}/>
                            :
                            isTablet ?
                                <img alt={card.imageAlt} src={getFileName(imagePath,card.image.tablet).default}/>
                            :
                                <img alt={card.imageAlt} src={getFileName(imagePath,card.image.mobile).default}/>
                            )} 
                        </div>
                        <h1 className={`${classes.ArticleSection__title}`} dangerouslySetInnerHTML={OutputHTML(card.headline)}></h1>
                        <p className={`${classes.ArticleSection__type}`} dangerouslySetInnerHTML={OutputHTML(card.type)}></p>
                    </a>
                ))}
            </div>
        </div>
    )
}

export default ArticleSection