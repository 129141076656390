//////// Package Imports 
import React, {useRef, useEffect, useState} from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//////// Component Imports 
import NavItem from "../NavItem";

//////// Helper Imports 
import { trackCustomEvent } from "../../../../helpers/tracking"
import useDocumentHeight from "../../../../helpers/useDocumentHeight"

//////// Data Imports 
import GlobalData from '../../../../data/global';

//////// Styling Imports 
import * as classes from './index.module.scss';

function SectionPins({controller, items, onPinSelectedCallback, navItemsPinTrigger, navItemsSelectionTrigger}) {

    gsap.registerPlugin(ScrollTrigger);

    let docHeight = useDocumentHeight();

    let navContainerScrollTrigger = useRef(null)
    let navNavItemSelectionScrollTriggers = useRef([])
    let [selectedNavStates, setSelectedNavStates] = useState(null)
    let navContainerRef = useRef(null)
    let pinRef = useRef(null)

    function togglePin(){
        let theClasses = [classes.SectionPins__pin, "global_pinned"];
        theClasses.forEach(myClass => navContainerRef.current.classList.toggle(myClass) )
    }

    function onTabClick(title, index){
        let tabTitle = title.replace(/(<([^>]+)>)/gi, " "); 

        let trackObject = {
            category: GlobalData.tracking.gaCategory,
            action: "Click",
            label: "Section " +tabTitle
        }
        trackCustomEvent(trackObject)

        // console.log(trackObject.label)
    }

    useEffect(() => {

        //updateSelectedNavIndex(2)

        const cleanupInstances = {
            navContainerScrollTrigger:navContainerScrollTrigger,
        }
        
        initScrollTrigger();

        return () => {
            
            onUnmount(cleanupInstances)

        }

    },[])

    function onUnmount(cleanupInstances){
        cleanupInstances.navContainerScrollTrigger.current.kill()
    }

    function initScrollTrigger(){

        navContainerScrollTrigger.current = ScrollTrigger.create({
            trigger:navItemsPinTrigger.current,
            scroller:controller.current,
            pin: navContainerRef.current,
            horizontal: true,
            start:() => `left left`,
            end:() => `right right`,
            pinSpacing:false
        })

        navItemsSelectionTrigger.current.map((item, index) => {
            navNavItemSelectionScrollTriggers.current[index] =  ScrollTrigger.create({
                trigger:item.current,
                scroller:controller.current,
                horizontal: true,
                scrub:true,
                start:() => `left right`,
                end:() => `right right`,
                onEnter: () => {
                    updateSelectedNavIndex(index);
                    togglePin();
                },
                onEnterBack: () => {
                    updateSelectedNavIndex(index);
                    togglePin();
                },
                pinSpacing:false,
                onLeave:togglePin,
                onLeaveBack: togglePin
                // markers:true
            })
        })

    }
    
    function updateSelectedNavIndex(index){
        let temp = []
        for (let i = 0; i < navItemsSelectionTrigger.current.length; i++) {
            temp[i] = false;
        }
        temp[index] = true
        setSelectedNavStates(temp)
    }

    useEffect(() => {
        ScrollTrigger.refresh(true);
    },[docHeight])

    return (

        <div ref={navContainerRef} className={classes.SectionPins}>
            {items.length && items.map((item, index) => {
                
                return (
                    <NavItem ref={pinRef} selectedNavStates={selectedNavStates && selectedNavStates[index]} id={item.title} key={index}>
                        <div 
                            className={classes.SectionPins__pin}
                            onClick={() => {onPinSelectedCallback(item, index); onTabClick(item.title, index)}}
                            
                        >
                            <span >
                                <h1 className={`NationalCondensed-black`}>{item.title.replace(/(<([^>]+)>)/gi, " ")}</h1>
                            </span>
                        </div>
                    </NavItem>
                )
            })}
        </div>

    )

}

export default SectionPins